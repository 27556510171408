import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import Link from '/src/components/LocalizedLink';
import Faq from '../../components/Faq';
import Layout from '../../components/Layout';
import useLocale from '../../hooks/useLocale';

import imgPhoto1 from '../../assets/images/student-visas/photo 1 (5).png';
import imgPhoto2 from '../../assets/images/student-visas/Photo 2 (5).png';
import imgPhoto3 from '../../assets/images/student-visas/Photo 3 (3).png';
import imgPhoto4 from '../../assets/images/student-visas/Photo 4 (1).png';
import imgDots from '../../assets/images/student-visas/Dots (1).svg';
import IconArrowRightRed from '../../assets/images/svg/arrow-right-red.inline.svg';
import imgBeforeSet from '../../assets/images/student-visas/Before you set off.svg';
import imgOnceInBarcelona from '../../assets/images/student-visas/Once you are in Barcelona.svg';
import iconTick from '../../assets/images/svg/tick.svg';

import fileApplicationStudentVisa from '../../assets/files/Spanish-student-visa-application-form.pdf';

import '../../assets/styles/pages/student-visas-page.scss';

const option1Title = `Trinity CertTESOL + Developing Teacher Programme`;
const option1TitleES = `Trinity CertTESOL + Programa de Curso Docente Avanzado`;
const option1 = `
<p>This programme is for you if you want to get qualified and then continue to work on your professional development as a teacher. The package includes:</p>
<br/>
<ul class='faq-list'>
    <li class='faq-list__item'>1 month Trinity CertTESOL course</li>
    <li class='faq-list__item'>11 months Developing Teacher</li>
    <li class='faq-list__item'>Support from our experienced visa coordinator
    </li>
    <li class='faq-list__item'>Assistance in gathering all your documents, applying for the visa and bureaucracy once you arrive in Barcelona.</li>
    <li class='faq-list__item'><b>Total: 3000€</b> (Save 300€!)</li>
</ul><br/>
<p>All courses must be paid in full in order to apply for the student visa.</p>
`;
const option1ES = `
<p>Este programa es para ti si quieres capacitarte y luego continuar trabajando en tu desarrollo profesional como profesor/a de inglés. El paquete incluye:</p>
<br/>
<ul class='faq-list'>
    <li class='faq-list__item'>1 mes de curso intensivo Trinity CertTESOL</li>
    <li class='faq-list__item'>11 meses de Curso Docente Avanzado</li>
    <li class='faq-list__item'>Apoyo de nuestra coordinación de visados internacionales.
    </li>
    <li class='faq-list__item'>Asistencia en la recopilación de todos tus documentos, solicitud del visado y trámites burocráticos una vez llegues a Barcelona</li>
    <li class='faq-list__item'><b>Total: 3000€</b> (¡Ahorra 300€!)</li>
</ul><br/>
<p>Todos los cursos deben pagarse en su totalidad para poder solicitar la visa de estudiante</p>
`;
const option2Title = `Trinity CertTESOL + Developing Teacher & Spanish programme`;
const option2TitleES = `Trinity CertTESOL + Curso Docente Avanzado + Programa de Catalán`;
const option2 = `
<p>This programme is for you if you want to get qualified, continue to work on your professional development as a teacher and achieve fluency in Spanish with 10 hours/week of lessons! The package includes:</p>
<br/>
<ul class='faq-list'>
    <li class='faq-list__item'>1 month Trinity CertTESOL course</li>
    <li class='faq-list__item'>11 months Developing Teacher & Spanish</li>
    <li class='faq-list__item'>10 hours/week of Spanish lessons from professional, qualified teachers</li>
    <li class='faq-list__item'>Flexible Spanish course schedule</li>
    <li class='faq-list__item'>Weekly teacher training workshops</li>
    <li class='faq-list__item'>Access to job opportunities before they hit the market</li>
    <li class='faq-list__item'>Access to our large database of lesson plans</li>
    <li class='faq-list__item'>Support from our experienced visa coordinator</li>
    <li class='faq-list__item'>Assistance in gathering all your documents, applying for the visa and bureaucracy once you arrive in Barcelona</li>
</ul><br/><b>Total: 4500€ (Save €150!)</b><br/><br/>
<p>Note: you must do 12 months if you want to qualify for a long-term visa and be able to work. You will also have the option to extend if you wish.
</p><br/>
<p>All courses must be paid in full in order to apply for the student visa.
</p>
`;
const option2ES = `
<p>¡Este programa es para ti si quieres formarte, continuar trabajando en tu desarrollo profesional como profesor/a de inglés y lograr fluidez en catalán con 10 horas/semana de clases! El paquete incluye:
</p>
<br/>
<ul class='faq-list'>
    <li class='faq-list__item'>1 mes de curso intensivo Trinity CertTESOL.</li>
    <li class='faq-list__item'>11 meses del Curso Docente Avanzado y catalán, (o algún otro idioma que te apasione).</li>
    <li class='faq-list__item'>10 horas/semana de clases de catalan con profesores expertos.</li>
    <li class='faq-list__item'>10 horas/semana de clases de catalán con profesores experimentados.</li>
    <li class='faq-list__item'>Apoyo de nuestra coordinación de visados internacionales.
    </li>
    <li class='faq-list__item'>Asistencia en la recopilación de todos tus documentos, solicitud del visado y trámites burocráticos una vez llegues a Barcelona.</li>
    <li class='faq-list__item'><b>Total: 4500€</b> (¡Ahorra €150!)</li>
</ul><br/>
<p>Todos los cursos deben pagarse en su totalidad para poder solicitar la visa de estudiante.</p>
`;

const package1Title = `Developing Teacher Programme`;
const package1TitleES = `Curso Docente Avanzado`;
const package1 = `
<p>This programme is for you if you want to continue to work on your professional development as a teacher. The package includes:
</p>
<br/>
<ul class='faq-list'>
    <li class='faq-list__item'>12-month programme</li>
    <li class='faq-list__item'>20 hours/week Developing Teacher course</li>
    <li class='faq-list__item'>Support from our experienced visa coordinator</li>
    <li class='faq-list__item'>Assistance in gathering all your documents, applying for the visa and bureaucracy once you arrive in Barcelona.
    </li>
    <li class='faq-list__item'><b>Total: 1500€</b> (Save €300!)</li>
</ul><br/>
<p>If you do not want to do 12 months, this course is priced at 150€/month.
All courses must be paid in full in order to apply for the student visa.
</p>
`;
const package1ES = `
<p>Este programa es para ti si quieres seguir avanzando en tu desarrollo profesional como profesor/a de inglés. El paquete incluye:
</p>
<br/>
<ul class='faq-list'>
    <li class='faq-list__item'>Programa de 12 meses de formación.</li>
    <li class='faq-list__item'>20 horas/semana del Curso Docente Avanzado.</li>
    <li class='faq-list__item'>Apoyo de nuestra coordinación de visados internacionales.</li>
    <li class='faq-list__item'>Asistencia en la recopilación de todos tus documentos, solicitud del visado y trámites burocráticos una vez llegues a Barcelona.
    </li>
    <li class='faq-list__item'><b>Total: 1500€</b> (¡Ahorra 300€!)</li>
</ul><br/>
<p>Para obtener un visado de estudiante, el tiempo mínimo de estudios requeridos es de 7 meses. Si quisieras estudiar por menos de un año, este curso tiene un precio de 150€/mes
Todos los cursos deben pagarse en su totalidad para poder solicitar la visa de estudiante.
</p>
`;

const package2Title = `Developing Teacher & Spanish programme 
`;
const package2TitleES = `Curso Docente Avanzado + Programa de Catalán`;
const package2 = `
<p>This programme is for you if you are already qualified and continue to work on your professional development as a teacher, as well as achieve fluency in Spanish with 10 hours/week of lessons! </p>
<br/>
<p>The package includes:</p><br/>
<ul class='faq-list'>
    <li class='faq-list__item'>12 months Developing Teacher & Spanish </li>
    <li class='faq-list__item'>10 hours/week of Spanish lessons from professional, qualified teachers</li>
    <li class='faq-list__item'>Flexible Spanish course schedule</li>
    <li class='faq-list__item'>Weekly teacher training workshops</li>
    <li class='faq-list__item'>Access to job opportunities before they hit the market</li>
    <li class='faq-list__item'>Access to our large database of lesson plans</li>
    <li class='faq-list__item'>Support from our experienced visa coordinator</li>
    <li class='faq-list__item'>Assistance in gathering all your documents, applying for the visa and bureaucracy once you arrive in Barcelona.</li>
</ul><br/><b>Total: 3000€</b><br/><br/>
<p>Note: you must do 12 months if you want to qualify for a long-term visa and be able to work. You will also have the option to extend if you wish.
</p><br/>
<p>All courses must be paid in full in order to apply for the student visa.

</p>
`;

const package2ES = `
<p>¡Este programa es para ti si quieres formarte, continuar avanzando en tu desarrollo profesional como profesor/a de inglés y lograr fluidez en otro idioma con 10 horas/semana de clases! El paquete incluye:</p>
<br/>
<ul class='faq-list'>
    <li class='faq-list__item'>Programa de 12 meses de formación.</li>
    <li class='faq-list__item'>10 horas/semana de clases de catalan (o algún otro idioma que te apasione) con profesores expertos.</li>
    <li class='faq-list__item'>10 horas/semana del Curso Docente Avanzado.</li>
    <li class='faq-list__item'>Apoyo de nuestra coordinación de visas internacionales.
    </li>
    <li class='faq-list__item'>Asistencia en la recopilación de todos tus documentos, solicitud del visado y trámites burocráticos una vez llegues a Barcelona.</li>
    <li class='faq-list__item'><b>Total: 3000€ </b> (¡Ahorra 300€!)</li>
</ul><br/>
<p>Para obtener un visado de estudiante, el tiempo mínimo de estudios requeridos es de 7 meses. Si quieres estudiar por menos de un año, este curso tiene un precio de 275€/mes. Todos los cursos deben pagarse en su totalidad para poder solicitar la visa de estudiante.
</p>
`;

const faq1Title = `How many hours can I work if I have a student visa?`;
const faq1Description = `Officially up to 30 hours per week.`;
const faq1TitleES = `¿Cuántas horas puedo trabajar si tengo una visa de estudiante?`;
const faq1DescriptionES = `Oficialmente hasta 20 horas por semana.`;

const faq2Title = `How long does it take to process a student visa?`;
const faq2Description = `It varies from country to country but you should give yourself 3 months to gather all of your documents, get an appointment at the consulate and get your visa approved. `;
const faq2TitleES = `¿Cuánto tiempo lleva procesar una visa de estudiante?`;
const faq2DescriptionES = `Varía de un país a otro, pero en promedio, deben considerarse unos 3 meses de principio a fin.`;

const faq3Title = `Can I extend my student visa from within Spain?`;
const faq3Description = `Yes, if you have a long-term visa (7 months or more) you can extend from within Spain by signing up to further courses.`;
const faq3TitleES = `¿Puedo tramitar un visado desde dentro de España?`;
const faq3DescriptionES = `No, no con un curso de nuestra categoría, que es 'formación profesional'. Es posible presentar una solicitud desde dentro de España si te inscribes en clases de catalán en una escuela o universidad de español acreditada por Cervantes o por el Ajuntament de Barcelona siempre que lo hagas dentro de los 30 días posteriores a tu llegada. Te recomendamos que siempre solicites el visado de estudiante desde tu país de origen o residencia. `;

// const faq4Title = `Can I combine courses to process a visa?`;
// const faq4Description = `Yes, you could do 6 months of Spanish and 6 months of the Developing Teacher course, for example.`;
// const faq4TitleES = `¿Puedo extender mi visado de estudiante desde dentro de España?`;
// const faq4DescriptionES = `Si. Si tienes un visado de larga duración (7 meses o más), puedes extenderlo desde dentro de España, extendiendo tu Curso de Docente Avanzado.`;

const faq5Title = `Can I process a visa from within Spain?`;
const faq5Description = `Yes. If you arrive in Spain as a tourist, you can apply for a student visa, as long as you submit your application within 30 days of arriving. `;
const faq5TitleES = `¿Puedo combinar cursos para tramitar una visa?`;
const faq5DescriptionES = `Sí, podrías hacer 6 meses de catalá u otro idioma que te apasione y 6 meses del Curso Docente Avanzado, por ejemplo.`;

const faq6Title = `I already have a student visa with a different school. Can I renew with TEFL Iberia?`;
const faq6Description = `Yes, but your previous course must be related to teaching or Spanish. You need a certificate of completion from your old school (certificado de aprovechamiento) and an enrolment letter from us to take to the administration office. `;
const faq6TitleES = `Ya tengo una visa de estudiante con una escuela diferente. ¿Puedo renovar con TEFL Iberia?`;
const faq6DescriptionES = `Sí, pero tu curso anterior debe estar relacionado con la enseñanza de inglés. Necesitarás un certificado de finalización de su escuela anterior (certificado de aprovechamiento) y una carta de inscripción nuestra para llevar a la oficina de administración. ¡No te preocupes, te guiaremos en el proceso!`;

const faq7Title = `Can’t I just apply for a straight-forward work visa?`;
const faq7Description = `Nowadays it is very difficult. The sponsorship process is a large commitment for an employer and it is very hard to find a company willing to take it on. Even if you do, you would have to involve costly immigration lawyers and prove that someone from the EU can’t do the job, which is extremely difficult.`;
const faq7TitleES = `¿No puedo simplemente solicitar una visa de trabajo directamente?`;
const faq7DescriptionES = `Hoy en día es muy difícil. El proceso de patrocinio es un gran compromiso para un empleador y es muy difícil encontrar una empresa dispuesta a asumirlo. Incluso si lo hace, tendría que involucrar a costosos abogados de inmigración y demostrar que alguien de la UE no puede hacer el trabajo, lo cual es extremadamente difícil.`;

const faq8Title = `Can I leave the EU to reset my tourist visa?`;
const faq8Description = `Yes, but you would have to leave the EU for a minimum of 3 months.`;
const faq8TitleES = `¿Puedo salir de la UE para restablecer mi visa de turista?`;
const faq8DescriptionES = `Sí, pero tendrías que salir de la UE durante un mínimo de 3 meses. Solo recuerda que con una visa de turista no podrás trabajar en España. `;

const faq9Title = `How easy is it to find private classes in Barcelona?`;
const faq9Description = `Very easy, we show you exactly how to do it on the TEFL course.`;
const faq9TitleES = `¿Qué tan fácil es encontrar clases particulares en Barcelona?`;
const faq9DescriptionES = `Muy fácil, te mostramos exactamente cómo hacerlo en el curso TEFL.`;

const StudentVisas = () => {
  const { enLocale } = useLocale({ matchPathName: '/es/' });

  return (
    <Layout
      title={{ id: 'studentVisas.seo.title' }}
      description={{ id: 'studentVisas.seo.description' }}
    >
      <div className="student-visas-page">
        <section className="c-first-section c-first-section--md">
          <div className="container">
            <h1 className="title">
              <FormattedMessage id="studentVisas.sec1.title" />
            </h1>
            <p className="description">
              <FormattedMessage id="studentVisas.sec1.description" />
            </p>
          </div>
        </section>
        <section className="second-section c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="block_l">
                  <h2 className="c-title-34 block_l__title">
                    <FormattedMessage id="studentVisas.sec2.title" />
                  </h2>
                  <p className="c-text-18 block_l__description">
                    Are you from the UK, USA, Canada, Australia or any other non-EU country? You can
                    still make your dream of teaching in Spain a reality with our student visa
                    programme! A 1-year student visa will allow you to work up to 30 hours per week,
                    so you can teach English, learn Spanish and enjoy everything Barcelona has to
                    offer.
                  </p>
                  <Link to={'/brexit-guide'} className="c-btn c-btn--red-border">
                    <FormattedMessage id="menu.brexit-guide" />
                    <IconArrowRightRed />
                  </Link>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="block_r">
                  <img src={imgPhoto1} alt="imgPhoto1" className="block_r__img" />
                  <img src={imgDots} alt="imgDots" className="dots" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="third-section c-section-includes c-faqs-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="block_l">
                  <div className="block_l__img">
                    <img
                      src={imgPhoto2}
                      alt="If you want to get qualified as a teacher"
                      className="img-main"
                    />
                  </div>
                  <div className="block_l__img-c">
                    <img src={imgPhoto3} alt="You have two options for obtaining a student visa" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="block_r">
                  <h2 className="c-title-34">
                    <FormattedMessage id="studentVisas.sec3.op1.title" />
                  </h2>
                  {/* <h3 className="c-title-24">
                    <FormattedMessage id="studentVisas.sec3.op1.description" />
                  </h3> */}
                  {/* <Faq
                    title={enLocale ? option1Title : option1TitleES}
                    description={enLocale ? option1 : option1ES}
                  /> */}
                  <Faq
                    title={enLocale ? option2Title : option2TitleES}
                    description={enLocale ? option2 : option2ES}
                  />

                  <div className="block_r__price block_r__price--full">
                    <h2 className="c-title-34">
                      <FormattedMessage id="studentVisas.sec3.op2.title" />
                    </h2>
                    {/* <h3 className="c-title-24">
                      <FormattedMessage id="studentVisas.sec3.op2.description" />
                    </h3>
                    <Faq
                      title={enLocale ? package1Title : package1TitleES}
                      description={enLocale ? package1 : package1ES}
                    /> */}
                    <Faq
                      title={enLocale ? package2Title : package2TitleES}
                      description={enLocale ? package2 : package2ES}
                    />
                  </div>

                  <div className="block_r__btns">
                    <p className="bottom-link">
                      <FormattedMessage id="url.tefl-spanish-course-barcelona">
                        {(locale) => (
                          <Link to={locale[0]} className="c-btn c-btn--red">
                            Find out more about our Developing Teacher & Spanish Programme
                          </Link>
                        )}
                      </FormattedMessage>
                      <br />
                      <br />
                      <FormattedMessage
                        id="studentVisas.sec3.notice"
                        values={{
                          a: (chunk) => (
                            <FormattedMessage id="url.courses.dates.index">
                              {(locale) => (
                                <Link to={locale[0]} className="red-link">
                                  {chunk}
                                </Link>
                              )}
                            </FormattedMessage>
                          ),
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="fourth-section fourth-section--preview c-section-py">
          <div className="container">
            <h2 className="fourth-section__title c-title-34">Spanish Visas Explained</h2>
            <p className="fourth-section__description c-text-18">
              Spanish bureaucracy can seem daunting but we’re here to make it as simple as possible
              for you. There are many different types of visa available, but the one that is best
              adapted to the needs of an English teacher is a <b> long-term student visa.</b>
            </p>
            <p className="fourth-section__description c-text-18">
              In order to qualify for a long-term student visa you must sign up to a course that is
              at least <b>20 hours/week and 7-12 months long.</b> At TEFL Iberia we have created the
              Developing Teacher and Spanish Programme to meet this requirement.
            </p>
            <div className="row first-row">
              <div className="col-md-12 col-12">
                <div className="block_l">
                  {/* <h3 className="block_l__title">
                  
                  </h3> */}
                  <div className="c-benefit-list">
                    <h4 className="c-text-18">
                      <b>20 hours per week of study</b>
                    </h4>
                    <div className="benefit">
                      <p className="c-text-16">
                        We keep this as flexible as possible so as to not interfere with your work
                        commitments. All the classes take place in the morning, as most English
                        teaching work is in the afternoon. You can also take regular study breaks to
                        give you time to focus on working, travelling and enjoying Barcelona.
                      </p>
                    </div>
                    <h4 className="c-text-18">
                      <b>Enrol in a recognised school</b>
                    </h4>
                    <div className="benefit">
                      <p className="c-text-16">
                        TEFL Iberia is accredited by Trinity College London and our partner school
                        is accredited by the Instiututo Cervantes, meeting all the requirements to
                        apply for a student visa in Spain.
                      </p>
                    </div>
                    <h4 className="c-text-18">
                      <b>12 months of study</b>
                    </h4>
                    <div className="benefit">
                      <p className="c-text-16">
                        In order to qualify for a long-term student visa, you should enrol in a
                        course that is 7-12 months long. You can do a shorter course in order to get
                        a short-term visa, but you won’t be able to work, and you won’t be able to
                        renew your visa from Spain. If you’d like to read more about different types
                        of Spanish visas and their requirements,{' '}
                        <Link
                          className="red-link"
                          to="/blog/navigating-spanish-work-visa-requirements-for-english-teachers/"
                        >
                          check out our detailed guide.
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-12 col-12">
                <div className="block_r">
                  <h3 className="block_r__title">
                    <FormattedMessage id="studentVisas.sec4.b2.title" />
                  </h3>
                  <div className="c-benefit-list">
                    <div className="benefit">
                      <p className="c-text-16">
                        <FormattedMessage id="studentVisas.sec4.b2.text1" />
                      </p>
                    </div>
                    <div className="benefit">
                      <p className="c-text-16">
                        <FormattedMessage id="studentVisas.sec4.b2.text2" />
                      </p>
                    </div>
                  </div>{' '}
                  <p className="block_r__subtitle">
                    <FormattedMessage
                      id="studentVisas.sec4.b2.price"
                      values={{
                        b: (chunk) => <b>{chunk}</b>,
                        b2: (chunk) => <b>{chunk}</b>,
                      }}
                    />
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </section>

        <section className="fourth-section c-section-py">
          <div className="container">
            <h2 className="fourth-section__title c-title-34">
              <FormattedMessage id="studentVisas.sec5.title" />
            </h2>
            <div className="row first-row">
              <div className="col-md-6 col-12">
                <div className="block_l">
                  <img src={imgBeforeSet} alt="imgBeforeSet" className="block_l__img" />
                  <h3 className="block_l__title">
                    <FormattedMessage id="studentVisas.sec5.b1.title" />
                  </h3>
                  <div className="c-benefit-list">
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="studentVisas.sec5.b1.ben1" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="studentVisas.sec5.b1.ben2" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="studentVisas.sec5.b1.ben3" />
                      </p>
                    </div>
                    {/* <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="studentVisas.sec5.b1.ben4" />
                      </p>
                    </div> */}
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="studentVisas.sec5.b1.ben5" />
                      </p>
                    </div>
                    {enLocale && (
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">
                          <FormattedMessage id="studentVisas.sec5.b1.ben6" />
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="block_r">
                  <img src={imgOnceInBarcelona} alt="imgOnceInBarcelona" className="block_r__img" />
                  <h3 className="block_r__title">
                    <FormattedMessage id="studentVisas.sec5.b2.title" />
                  </h3>
                  <div className="c-benefit-list">
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage
                          id="studentVisas.sec5.b2.ben1"
                          values={{
                            a: (chunk) => (
                              <a
                                target="_blank"
                                href="https://www.barcelona-metropolitan.com/living/obtaining-a-nie-in-barcelona/"
                                className="red-link text-fw-600"
                              >
                                {chunk}
                              </a>
                            ),
                          }}
                        />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="studentVisas.sec5.b2.ben2" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="studentVisas.sec5.b2.ben3" />
                      </p>
                    </div>
                    <p className="block_r__subtitle">
                      <FormattedMessage id="studentVisas.sec5.b2.text" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row second-row">
              <div className="col-md-6 col-12">
                <div className="block_l">
                  <div className="block_l__img">
                    <img src={imgPhoto4} alt="imgPhoto4" />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="block_r">
                  <h3 className="block_r__title">
                    <FormattedMessage id="studentVisas.sec6.title" />
                  </h3>
                  <p className="block_r__subtitle">
                    <FormattedMessage id="studentVisas.sec6.benText" />
                  </p>
                  <div className="c-benefit-list">
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="studentVisas.sec6.ben1" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage
                          id="studentVisas.sec6.ben2"
                          values={{
                            a: (chunk) => (
                              <a
                                target="_blank"
                                href={fileApplicationStudentVisa}
                                download
                                className="red-link"
                              >
                                {chunk}
                              </a>
                            ),
                          }}
                        />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="studentVisas.sec6.ben3" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="studentVisas.sec6.ben4" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="studentVisas.sec6.ben5" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="studentVisas.sec6.ben6" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="studentVisas.sec6.ben7" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="studentVisas.sec6.ben8" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="studentVisas.sec6.ben9" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="studentVisas.sec6.ben10" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="fifth-section c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="block_l">
                  <h2 className="block_l__title c-title-34">
                    <FormattedMessage
                      id="studentVisas.sec7.title"
                      values={{
                        accent: (chunk) => <span className="colored no-link">{chunk}</span>,
                      }}
                    />
                  </h2>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="block_r">
                  <p className="block_r__par c-text-18">
                    <FormattedMessage
                      id="studentVisas.sec7.text1"
                      values={{
                        a: (chunk) => (
                          <a href="mailto:coordination@tefl-iberia.com" className="colored">
                            {chunk}
                          </a>
                        ),
                        a2: (chunk) => (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://calendly.com/tefliberia/30min/"
                            className="colored"
                          >
                            {chunk}
                          </a>
                        ),
                      }}
                    />
                  </p>
                  <p className="block_r__par c-text-18">
                    <FormattedMessage
                      id="studentVisas.sec7.text2"
                      values={{
                        a: (chunk) => (
                          <a
                            target="_blank"
                            href="https://www.facebook.com/groups/2229995425"
                            className="colored text-fw-600"
                          >
                            {chunk}
                          </a>
                        ),
                        a2: (chunk) => (
                          <a
                            target="_blank"
                            href="https://www.facebook.com/groups/41751319472/"
                            className="colored text-fw-600"
                          >
                            {chunk}
                          </a>
                        ),
                      }}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="c-faqs-section">
          <div className="container">
            <div className="c-faqs-section__title">
              <h2 className="c-title-34">
                <FormattedMessage id="common.faqs" />
              </h2>
              <FormattedMessage id="url.courses.faq">
                {(locale) => (
                  <Link to={locale[0]} className="c-btn c-btn--white">
                    <FormattedMessage id="btn.viewMoreFaqs" />
                    <IconArrowRightRed />
                  </Link>
                )}
              </FormattedMessage>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Faq
                  title={enLocale ? faq1Title : faq1TitleES}
                  description={enLocale ? faq1Description : faq1DescriptionES}
                />
                <Faq
                  title={enLocale ? faq2Title : faq2TitleES}
                  description={enLocale ? faq2Description : faq2DescriptionES}
                />
                <Faq
                  title={enLocale ? faq3Title : faq3TitleES}
                  description={enLocale ? faq3Description : faq3DescriptionES}
                />
                {/* <Faq
                  title={enLocale ? faq4Title : faq4TitleES}
                  description={enLocale ? faq4Description : faq4DescriptionES}
                /> */}
                <Faq
                  title={enLocale ? faq5Title : faq5TitleES}
                  description={enLocale ? faq5Description : faq5DescriptionES}
                />
              </div>
              <div className="col-lg-6">
                <Faq
                  title={enLocale ? faq6Title : faq6TitleES}
                  description={enLocale ? faq6Description : faq6DescriptionES}
                />
                <Faq
                  title={enLocale ? faq7Title : faq7TitleES}
                  description={enLocale ? faq7Description : faq7DescriptionES}
                />
                <Faq
                  title={enLocale ? faq8Title : faq8TitleES}
                  description={enLocale ? faq8Description : faq8DescriptionES}
                />
                <Faq
                  title={enLocale ? faq9Title : faq9TitleES}
                  description={enLocale ? faq9Description : faq9DescriptionES}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default StudentVisas;
